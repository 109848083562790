import * as React from "react"
import { MDXProvider } from "@mdx-js/react"
import styled from "styled-components"
import GlobalNavigation from "./global-nav/global-nav"
import PropTypes from "prop-types"
import { base } from "./styles/ColorStyles"
import "./layout.css"

const ContentWrapper = styled.div`
  margin: 0;
`

const BlogLayoutWrapper = styled.div`
  margin: 0 auto;
  max-width: 900px;
  /* border-left: 1px dashed ${base.border};
  border-right: 1px dashed ${base.border}; */
  padding-top: 2rem;
  padding-bottom: 5rem;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
  @media (max-width: 426px) {
    padding: 3rem 1rem;
  }
`

const PostLayout = ({ children }) => {
  return (
    <>
      <ContentWrapper>
        {/* <BackButton /> */}
        <GlobalNavigation />
        <BlogLayoutWrapper>
          <MDXProvider
            components={
              {
                // Map HTML element tag to React component
                // li: props => <Li {...props} />,
              }
            }
          >
            {children}
          </MDXProvider>
        </BlogLayoutWrapper>
      </ContentWrapper>
    </>
  )
}

PostLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PostLayout
